import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { accessApi, apiPortal, responseApi } from '@/provider'
import { toEncrypt } from "../provider";
import mySettingsObject from 'my-app-settings'


let myValue = mySettingsObject.MODE_ON
// console.log(myValue, 'test config')

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inboxFitur: true,
    redeemFitur: false,
    storeFitur: true,
    bannerFitur: false,
    isLoading: true,
    user: null,
    profile: null,
    das: 9,
    token: null,
    url: null,
    listgame: [],
    detailgame: {},
    tokenLife: null,
    statusStore: false,
    gameId: null,
    gameIdUnix: null,
    coin: 0,
    point: 0,
    dataPoint: [],
    reward: null,
    leaderboardShadow: [
      {
        msisdn: "6282299006950",
        no: "1",
        point: "10000",
      },
      {
        msisdn: "6285281673770",
        no: "1",
        point: "9000",
      },
      {
        msisdn: "6281296712382",
        no: "1",
        point: "8000",
      },
      {
        msisdn: "6281387260050",
        no: "1",
        point: "7000",
      },
      {
        msisdn: "6285210097770",
        no: "1",
        point: "6000",
      },
    ],
    mode: myValue,
    // reward:[
      // {id: 1, name: 'Apple Watch',  url: require("@/assets/reward/star_applewatch.png")},
      // {id: 2, name: 'Galaxy Fit', url: require("@/assets/reward/star_galaxyfit.png")},
      // {id: 3, name: 'Iphone', url: require("@/assets/reward/star_iphone.png")},
      // {id: 4, name: 'Motor',  url: require("@/assets/reward/star_motor.png")},
      // {id: 5, name: 'Playstation',  url: require("@/assets/reward/star_playstation.png")},
      // {id: 6, name: 'Smart TV', url: require("@/assets/reward/star_smarttv.png")}
    // ],
    bannerList: [
      // {
      //   name: 'Pesta Reward',
      //   image: require('@/assets/pesta-reward.png'),
      //   url: 'leaderboard',
      // },
      // {
      //   name: 'Hadiah Langsung',
      //   image: require('@/assets/hadiah-langsung.png'),
      //   url: 'home',
      // },
    ],
    leaderboardList:[
      // {
      //   msisdn: "62812345xxxx",
      //   point: "10000"
      // },
      // {
      //   msisdn: "62812111xxxx",
      //   point: "9000"
      // },
      // {
      //   msisdn: "62822443xxxx",
      //   point: "8000"
      // },
      // {
      //   msisdn: "62812645xxxx",
      //   point: "7000"
      // },
      // {
      //   msisdn: "62822349xxxx",
      //   point: "6000"
      // },
      // {
      //   msisdn: "62822340xxxx",
      //   point: "5000"
      // },
      // {
      //   msisdn: "62811340xxxx",
      //   point: "4500"
      // },
      // {
      //   msisdn: "62812040xxxx",
      //   point: "3000"
      // },
      // {
      //   msisdn: "62822390xxxx",
      //   point: "2500"
      // },
      // {
      //   msisdn: "62811140xxxx",
      //   point: "1000"
      // },
    ],
    popupReward: [
      {
        point: "25",
        image: require("@/assets/hadiah-1gb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat reward langsung paket Data 1GB! Ambil rewardnya sekarang!"
      },
      {
        point: "26",
        image: require("@/assets/hadiah-100rb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat reward langsung Uang Tunai 100 ribu! Ambil rewardnya sekarang!"
      },
      {
        point: "27",
        image: require("@/assets/hadiah-500rb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat reward langsung Uang Tunai 500 ribu! Ambil rewardnya sekarang!"
      },
      {
        point: "28",
        image: require("@/assets/hadiah-1jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat reward langsung Uang Tunai 1 juta! Ambil rewardnya sekarang!"
      },
      {
        point: "29",
        image: require("@/assets/hadiah-1.5jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat reward langsung Uang Tunai 1,5 juta! Ambil rewardnya sekarang!"
      },
      {
        point: "30",
        image: require("@/assets/hadiah-2jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat reward langsung Uang Tunai 2 juta! Ambil rewardnya sekarang!"
      }
    ],
    redeemList: [
      {
        id: 1,
        name: "Gratis Pulsa 5 Ribu",
        image: require("@/assets/redeem-5k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      },
      {
        id: 2,
        name: "Gratis Pulsa 10 Ribu",
        image: require("@/assets/redeem-10k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      },
      {
        id: 3,
        name: "Gratis Pulsa 25 Ribu",
        image: require("@/assets/redeem-25k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      }
    ],
    inboxList: [],
    storeList: [
      {
        id: 1,
        name: "Berlangganan per hari",
        image: null,
        price: 1100,
        description: "Rp.1100/sms/hari",
        url: "sms://"
      },
      {
        id: 2,
        name: "Berlangganan per 2 hari",
        image: null,
        price: 2200,
        description: "Rp.2200/sms/2hari",
        url: "sms://"
      },
      {
        id: 3,
        name: "Berlangganan per 3 hari",
        image: null,
        price: 3300,
        description: "Rp.3300/sms/3hari",
        url: "sms://"
      },
      {
        id: 4,
        name: "Berlangganan per minggu",
        image: null,
        price: 5500,
        description: "Rp.5500/sms/minggu",
        url: "sms://"
      },
      {
        id: 5,
        name: "Berlangganan per 10 hari",
        image: null,
        price: 8800,
        description: "Rp.8800/sms/10hari",
        url: "sms://"
      },
      {
        id: 6,
        name: "Pembelian untuk 3 sesi (token)",
        image: null,
        price: 1100,
        description: "Rp.1100/sms",
        url: "sms://"
      }
    ],
    winnerList: [],
    rewardList: [],
    rewardDetail: {},
    popupToken: {
      point: null,
      image: require("@/assets/token-null.png"),
      title: "Token Tidak Cukup!",
      description:
        "Yaah, tokenmu tidak cukup untuk main game ini. Tambah dulu yuk tokenmu!"
    },
    // appKey: 'eyJhcHBfYWxpYXMiOiJ1dGVuZ2dvIiwiYXBwX25hbWUiOiJVbGFyIFRlbmdnbyIsImNwX2lkIjoxfQ==aGeWlvxnnXLxKA7fMKH1',
    appKey: 'eyJhcHBfYWxpYXMiOiJwb3J0YWxIdXRjaCIsImFwcF9uYW1lIjoiUG9ydGFsIEh1dGNoIiwiY3BfaWQiOjV9pNNpr46aKHSUOS4T',
    secretKey: 'Bismillah',
    telco: null,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    isUser: state => state.user,
    getUser: state => state.user,
    getSecret: state => state.secretKey,
    getGameId: state => state.gameId,
    getLifeId: state => state.tokenLife,
    getCoin: state => state.coin,
    getMode: state => state.mode,
    getRedeem: state => state.redeemList,
    getStore: state => state.storeList,
    getBanner: state => state.bannerList,
    getInbox: state => state.inboxList,
    getReward: state => state.popupReward,
    getLeaderboard: state => state.leaderboardList,
    getLeaderboardShadow: state => state.leaderboardShadow,
    getGameIdUnix: state => state.gameIdUnix,
    getWinnerList: state => state.winnerList,
    getRewardList: state => state.rewardList,
    getRewardDetail: state => state.rewardDetail,
  },
  mutations: {
    SET_TOKEN: (state, value) => value ? (state.token = value) : (state.token = null),
    SET_USER: (state, value) => value ? (state.user = value) : (state.user = null),
    SET_URL: (state, value) => value ? (state.url = value) : (state.url = null),
    SET_LIST_GAME: (state, value) => value ? (state.listgame = value) : (state.listgame = []), 
    SET_PROFILE: (state, value) => value ? (state.profile = value) : (state.profile = null),
    SET_TOKEN_LIFE: (state, value) => value ? (state.tokenLife = value) : (state.tokenLife = null),
    SET_DATA_POINT: (state, value) => value ? (state.dataPoint = value) : (state.dataPoint = []),
    SET_GAME_ID: (state, value) => value ? (state.gameId = value) : (state.gameId = null),
    SET_DETAIL_GAME: (state, value) => value ? (state.detailgame = value) : (state.detailgame = {}),
    SET_COIN: (state, value) => value ? (state.coin = value) : (state.coin = 0),
    SET_POINT: (state, value) => value ? (state.point = value) : (state.point = 0),
    SET_REWARD: (state, value) => value ? (state.reward = value) : (state.reward = null),
    SET_MODE: (state, value) => value ? (state.mode = value) : (state.mode = 'free'),
    SET_TELCO: (state, value) => value ? (state.telco = value) : (state.telco = null),
    SET_BANNER_LIST: (state, value) => value ? (state.bannerList = value) : (state.bannerList = []),
    SET_REWARD_LIST: (state, value) => value ? (state.popupReward = value) : (state.popupReward = []),
    SET_STORE_LIST: (state, value) => value ? (state.storeList = value) : (state.storeList = []),
    SET_LEADERBOARD_LIST: (state, value) => value ? (state.leaderboardList = value) : (state.leaderboardList = []),
    SET_LEADERBOARD_SHADOW: (state, value) => value ? (state.leaderboardShadow = value) : (state.leaderboardShadow = []),
    SET_INBOX_LIST: (state, value) => value ? (state.inboxList = value) : (state.inboxList = []),
    SET_GAME_ID_UNIX: (state, value) => value ? (state.gameIdUnix = value) : (state.gameIdUnix = null),
    SET_WINNER_LIST: (state, value) => value ? (state.winnerList = value) : (state.winnerList = []),
    SET_HADIAH_LIST: (state, value) => value ? (state.rewardList = value) : (state.rewardList = []),
    SET_HADIAH_DETAIL: (state, value) => value ? (state.rewardDetail = value) : (state.rewardDetail = {}),
  },
  actions: {
    getProfile: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameProfile', sendData, { headers })
          const dataProfile = await JSON.parse(responseApi(data))
          const JsonDataProfile = dataProfile
          commit('SET_TELCO', JsonDataProfile.telco)
          commit('SET_PROFILE', JsonDataProfile)
          // console.log('SET_TELCO', JsonDataProfile.telco)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDetail: ({commit, getters}) => {
      // console.log(getters.getMode, 'mode')
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameDetail', sendData, { headers })
          const dataDetail = await JSON.parse(responseApi(data))
          const JsonDataDetail = dataDetail
          // console.log(JsonDataDetail)
          // commit('SET_COIN', 1)
          commit('SET_COIN', JsonDataDetail.token)
          if (getters.getMode === 'free') {
            JsonDataDetail.point = getters.getPoint
            commit('SET_POINT', JsonDataDetail.point)
          } else {
            commit('SET_POINT', JsonDataDetail.point)
          }
          
          // console.log(process.env.VUE_APP_KEY, process.env.VUE_APP_SECRET);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getGames: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('games', { headers })
        const dataGames = JSON.parse(responseApi(data))
        console.log(dataGames);
        // let newData = dataGames.filter( item => {
        //   return item.id === '4271230733' || item.id === '1685614594' || item.id === '1449509162' || item.id === '4260850995'
        // })
        commit('SET_LIST_GAME', dataGames)
      })
    },
    getLeaderboard: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
        const sendData = { req: msisdn }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameLeaderboard', sendData, { headers })
        const dataLeaderboard = JSON.parse(responseApi(data))
        // console.log(dataLeaderboard);
        commit('SET_LEADERBOARD_LIST', dataLeaderboard)
      })
    },
    getInbox: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('playerInbox/list', { headers })
        commit('SET_INBOX_LIST', data.data)
      })
    },
    getBanner: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 4 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log('data banner',dataGames);
        commit('SET_BANNER_LIST', dataGames)
      })
    },
    getRedeem: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REDEEM_LIST', dataGames)
      })
    },
    getStore: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 2 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_STORE_LIST', dataGames)
      })
    },
    getReward: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 1 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REWARD_LIST', dataGames)
      })
    },
    getGameReward: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('gameReward', { headers })
        const res = responseApi(data)
        const dataReward = JSON.parse(res)
        // console.log(dataReward);
        commit('SET_REWARD', dataReward)
      })
    },
    getLife: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const unix = Math.round(+new Date()/1000);
          const unixSlice = unix.toString().slice(1, 10);
          const gameUnix = getters.getGameId+parseInt(unixSlice) 
          const datas = {
            game_id: gameUnix,
            msisdn: getters.getUser,
            type: "portal",
          }
          // console.log('data dikirim', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret) 
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameLife', sendData, { headers })
          const dataLife = await JSON.parse(responseApi(data))
          commit('SET_TOKEN_LIFE', dataLife.token_life)
          commit('SET_GAME_ID_UNIX', gameUnix)
          // console.log('getlife',dataLife);
          resolve(dataLife.token_life);
        }).catch(error => {
          reject(error)
        })
      })
    },
    storePoint: async ({commit, getters}, point) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const datas = {
            game_id: getters.getGameIdUnix,
            msisdn: getters.getUser,
            token_id: getters.getLifeId,
            data: point,
          }
          // console.log('store data', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret)
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gamePoint', sendData, { headers })
          const dataPoint = await JSON.parse(responseApi(data))
          // console.log('response store', dataPoint)
          commit('SET_DATA_POINT', dataPoint)
          resolve(dataPoint)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    listReward: ({commit, getters}) => {
      // accessApi().then(async (token) => {
      //   const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
      //   const sendData = { req: type }
      //   const headers = { Authorization : `bearer ${token}`}
      //   const { data } = await apiPortal.post('gameSet', sendData, { headers })
        // const dataGames = JSON.parse(responseApi(data))
        const data = [
          {
            id: 1,
            title: "Playstation 5",
            subTitle: "Priode 1",
            priode: "11 Maret -  26 Maret 2022",
            banner: require('@/assets/reward/ps5.png'),
          },
          {
            id: 2,
            title: "I Watch Series",
            subTitle: "Priode 2",
            priode: "27 Maret - 10 April 2022",
            banner: require('@/assets/reward/iwatch.png'),
          },
          {
            id: 3,
            title: "Apple Ipad Pro",
            subTitle: "Priode 3",
            priode: "11 April - 26 April 2022",
            banner: require('@/assets/reward/ipad.png'),
          },
          {
            id: 4,
            title: "Xbox Series X",
            subTitle: "Priode 4",
            priode: "27 April - 11 Mei 2022",
            banner: require('@/assets/reward/xbox.png'),
          },
          {
            id: 5,
            title: "Asus ROG",
            subTitle: "Priode 5",
            priode: "12 Mei - 27 Mei 2022",
            banner: require('@/assets/reward/asus.png'),
          },
          {
            id: 6,
            title: "Samsung Flip",
            subTitle: "Priode 6",
            priode: "28 Mei - 11 Juni 2022",
            banner: require('@/assets/reward/flip.png'),
          },
          {
            id: 7,
            title: "Samsung Fold",
            subTitle: "Priode 7",
            priode: "12 Juni - 26 Juni 2022",
            banner: require('@/assets/reward/fold.png'),
          },
          {
            id: 8,
            title: "Iphone 12 Pro Max",
            subTitle: "Priode 8",
            priode: "27 Juni - 11 Juli 2022",
            banner: require('@/assets/reward/iphone.png'),
          },
        ]
        const dataGames = data
        // console.log(dataGames);
        commit('SET_HADIAH_LIST', dataGames)
      // })
    },
    listRewardDetail: ({commit}, payload) => {
      // accessApi().then(async (token) => {
      //   const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
      //   const sendData = { req: type }
      //   const headers = { Authorization : `bearer ${token}`}
      //   const { data } = await apiPortal.post('gameSet', sendData, { headers })
        // const dataGames = JSON.parse(responseApi(data))
        const data = [
          {
            id: 1,
            title: "Playstation 5",
            priode: "26 Maret 2022",
            winner_date: "29 Maret 2022",
            reward_date: "07 April 2022",
            banner: require('@/assets/reward/ps5.png'),
            description: 'Mainkan teruss game seru didalam serba hoki bisa mendapatkan PS5 ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 2,
            title: "I Watch Series",
            priode: "10 April 2022",
            winner_date: "13 April 2022",
            reward_date: "07 Mei 2022",
            banner: require('@/assets/reward/iwatch.png'),
            description: 'Mainkan teruss game seru didalam serba hoki bisa mendapatkan I Watch Series ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 3,
            title: "Apple Ipad Pro",
            priode: "26 April 2022",
            winner_date: "29 April 2022",
            reward_date: "07 Mei 2022",
            banner: require('@/assets/reward/ipad.png'),
            description: 'Mainkan terus game seru didalam serba hoki bisa mendapatkan Apple Ipad Pro ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 4,
            title: "Xbox Series X",
            priode: "11 Mei 2022",
            winner_date: "14 Mei 2022",
            reward_date: "07 Juni 2022",
            banner: require('@/assets/reward/xbox.png'),
            description: 'Mainkan terus game seru didalam serba hoki bisa mendapatkan Xbox Seris X ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 5,
            title: "Asus ROG",
            priode: "27 Mei 2022",
            winner_date: "30 Mei 2022",
            reward_date: "07 Juni 2022",
            banner: require('@/assets/reward/asus.png'),
            description: 'Mainkan terus game seru didalam serba hoki bisa mendapatkan Asus ROG ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 6,
            title: "Samsung Flip",
            priode: "11 Juni 2022",
            winner_date: "14 Juni 2022",
            reward_date: "07 Juli 2022",
            banner: require('@/assets/reward/flip.png'),
            description: 'Mainkan terus game seru didalam serba hoki bisa mendapatkan Samsung Flip ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 7,
            title: "Samsung Fold",
            priode: "26 Juni 2022",
            winner_date: "29 Juni 2022",
            reward_date: "07 Juli 2022",
            banner: require('@/assets/reward/fold.png'),
            description: 'Mainkan terus game seru didalam serba hoki bisa mendapatkan Samsung Fold ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
          {
            id: 8,
            title: "Iphone 12Pro Max",
            priode: "11 Juli 2022",
            winner_date: "14 Juli 2022",
            reward_date: "20 Juli 2022",
            banner: require('@/assets/reward/iphone.png'),
            description: 'Mainkan terus game seru didalam serba hoki bisa mendapatkan Iphone 12 pro Max ini dengan cara mengumpulkan skor tertinggi selama periode berlangsung reward ini tanpa diundi lho!',
          },
        ]

        let newData = data.filter( item => {
          return item.id === parseInt(payload)
        })
        const dataGames = newData
        console.log(dataGames[0], parseInt(payload))
        commit('SET_HADIAH_DETAIL', dataGames[0])
      // })
    },
    // eslint-disable-next-line no-unused-vars
    listWinner: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('getLatestRewardEventWinner', sendData, { headers })
        const dataGames = data
        const dataWinner = dataGames.data
        commit('SET_WINNER_LIST', dataWinner)
        resolve(dataWinner);
      }).catch(error => {
        reject(error)
      })
    })
    },
  },
  modules: {
    // login
  }
});
